/**
 * Базовая инициализация AXIOS
 */
import axios from "axios";
import { API_URL } from "./constants";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "/v1/"
    : `${process.env.VUE_APP_API_URL || API_URL}/v1/`;

// Добавляем токен
const token = sessionStorage.getItem("token");
if (token !== undefined) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// Добавляем токен к каждому запросу
axios.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("token");
  if (token !== undefined) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axios;
