<template>
  <div class="image-preview">
    <button @click="$emit('close')" class="image-preview__close">
      <CloseLargeIcon />
    </button>
    <img :src="imageUrl" alt="" />
  </div>
</template>

<script>
import CloseLargeIcon from "../../assets/icons/CloseLarge.svg";

/**
 * Компонент для вывода лайтбокса с изображением товара
 */
export default {
  name: "PreviewImage",

  components: {
    CloseLargeIcon,
  },

  props: {
    imageUrl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.image-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: black;

  img {
    width: 100%;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 20;
    background: none;
    border: none;
  }
}
</style>
