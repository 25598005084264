import axios from "../../axios";
import { X_APP_TOKEN } from "@/constants";

/**
 * Хранилище товаров
 */
export default {
  namespaced: true,

  state: {
    loaded: false,
    items: [],
    categories: [],
    types: [],
    prices: [0, 0],
    cities: [],
    filter: {
      category: null,
      types: [],
      prices: [0, 9999999999],
    },
    products: [],
    filtering: false,
    basket: [],
  },

  mutations: {
    /**
     * Создание списка категорий
     * @param state
     * @param payload
     * @constructor
     */
    SET_CATEGORIES: (state, payload) => {
      state.categories = payload.category_flowers.map((item) => ({
        name: item.title,
        id: item.id,
        count: payload.items.filter(
          (x) => x.category_flowers_ids.indexOf(item.id) > -1
        ).length,
        image: null,
      }));

      state.categories.unshift({
        name: "Все",
        id: 0,
        count: payload.items.length,
        image: null,
      });
      state.filter.category = {
        id: 0,
      };
    },

    SET_ITEMS: (state, payload) => (state.items = payload),

    SET_TYPES: (state, payload) => (state.types = payload),

    SET_CITIES: (state, payload) => (state.cities = payload),

    SELECT_CATEGORY: (state, payload) => (state.filter.category = payload),

    SELECT_TYPES: (state, payload) => (state.filter.types = payload),

    /**
     * Создание диапазона минимальной и максимальной цены
     * @param state
     * @constructor
     */
    SET_PRICE_RANGE: (state) => {
      state.prices = state.filter.prices = [
        Math.min.apply(
          null,
          state.items.map((item) => item.price)
        ),
        Math.max.apply(
          null,
          state.items.map((item) => item.price)
        ),
      ];
    },

    SET_RANGES: (state, payload) => (state.filter.prices = payload),

    SET_LOADED: (state, payload) => (state.loaded = payload),

    /**
     * Фильтрация товаров по заданным значениям
     * @param state
     * @constructor
     */
    FILTER_PRODUCTS: (state) => {
      state.products = [];
      state.filtering = true;
      let items = state.items;

      // Фильтрация по категории
      if (state.filter.category.id !== 0) {
        items = items.filter(
          (x) => x.category_flowers_ids.indexOf(state.filter.category.id) > -1
        );
      }

      // фильтрация по типу цветка
      if (state.filter.types.length > 0) {
        items = items.filter((x) => {
          let founded = false;
          x.type_flowers_ids.forEach((item) => {
            if (state.filter.types.find((y) => y.id === item) !== undefined) {
              founded = true;
            }
          });
          return founded;
        });
      }

      items = items.filter(
        (x) =>
          x.price >= state.filter.prices[0] && x.price <= state.filter.prices[1]
      );

      state.products = items;
      state.filtering = false;
    },

    /**
     * Обновление содержимого корзины
     * @param state
     * @param payload
     * @constructor
     */
    UPDATE_CART: (state, payload = null) => {
      const cartStore = localStorage.getItem("cart");
      if (cartStore === null && payload !== null) {
        localStorage.setItem("cart", JSON.stringify([payload]));
        state.basket = [payload];
      } else {
        const cartData = JSON.parse(cartStore);
        if (payload !== null) {
          const foundIndex = cartData.findIndex(
            (x) => x.id === payload.id && x.size === payload.size
          );
          if (foundIndex > -1) {
            cartData[foundIndex].count += payload.count;
          } else {
            cartData.push(payload);
          }
          localStorage.setItem("cart", JSON.stringify(cartData));
          state.basket = cartData;
        } else if (cartData) {
          state.basket = cartData;
        }
      }
    },
  },

  actions: {
    /**
     * Загрузка товара и иницализацния даннхы
     * @param commit
     * @returns {Promise<void>}
     */
    async load({ commit }) {
      try {
        const { data } = await axios.get("/screens/flowers/", {
          headers: {
            "x-app-token": X_APP_TOKEN,
          },
        });

        commit("SET_CATEGORIES", data);
        commit(
          "SET_ITEMS",
          data.items
            .filter((x) => x.price)
            .map((item) => ({
              ...item,
              image: item.images[0]?.source.image,
            }))
        );
        commit("SET_TYPES", data.type_flowers);
        commit("SET_PRICE_RANGE", data.items);
        commit("FILTER_PRODUCTS");
        commit("SET_CITIES", data.cities);
        commit("UPDATE_CART");
        commit("SET_LOADED", true);
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    loaded: (state) => state.loaded,

    items: (state) => state.items,

    products: (state) => state.products,

    categories: (state) => state.categories,

    types: (state) => state.types,

    filter: (state) => state.filter,

    prices: (state) => state.prices,

    cities: (state) => state.cities,

    filtering: (state) => state.filtering,

    /**
     * Получение содержимого корзины и данных в ней
     * @param state
     * @param _
     * @param rootState
     * @returns {{count: number, sum: number, items: []}}
     */
    basket: (state, _, rootState) => {
      const items = [];
      let count = 0;
      let sum = 0;
      state.basket
        .filter((x) => x.count > 0)
        .forEach((item) => {
          if (+item.count > 0) {
            const product = state.items.find(
              (x) =>
                x._id === item.id && x.cities_ids.includes(rootState.City.city)
            );
            if (product) {
              let price = product.price;
              if (product.composition.length > 0) {
                price = +product.composition.find((x) => x.size === item.size)
                  .price;
              }

              items.push({
                id: product._id,
                image: product.image,
                name: product.name,
                description: product.description,
                price,
                count: item.count,
                size: item.size,
              });

              count += item.count;
              sum += item.count * price;
            }
          }
        });

      return {
        items,
        count,
        sum,
      };
    },
  },
};
