<template>
  <header class="header">
    <div class="header-top">
      <div class="header-top__logo">
        <LogoIcon v-if="visibleLogo" />
      </div>
      <div class="header-top__city">
        <CitySelector />
      </div>
    </div>
    <slot></slot>
  </header>
</template>

<script>
import LogoIcon from "../assets/icons/logo.svg";
import CitySelector from "../views/Home/CitySelector";

/**
 * Компонент шапки сайта
 */
export default {
  name: "VHeader",

  components: {
    CitySelector,
    LogoIcon,
  },

  data: () => ({
    visibleLogo: true,
  }),

  mounted() {
    if (sessionStorage.getItem("hideLogo") === "1") {
      this.visibleLogo = false;
    }
  },
};
</script>

<style lang="scss">
.header {
  position: relative;
  z-index: 100;
  height: 126px;
  width: 100%;
  background: white;
  background: linear-gradient(180deg, #c8ad7d 0%, rgba(200, 173, 125, 0) 100%);
  padding: 8px 15px;

  &-top {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 12px;

    &__logo {
      padding-left: 4px;
    }

    &__city {
      align-self: center;
    }
  }
}
</style>
