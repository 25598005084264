import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Index";
import Complete from "../views/Complete/Index";
import Cart from "../views/Cart/Index";
import ErrorPage from "../views/404";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/product/:id",
    name: "product",
    component: Home,
  },
  {
    path: "/complete",
    name: "complete",
    component: Complete,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
  },
  {
    path: "/404",
    name: "404",
    component: ErrorPage,
  },
  {
    path: "*",
    name: "error",
    component: ErrorPage,
  },
];

/**
 * Роутер приложения
 * @type {VueRouter}
 */
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  /**
   * Скролинг страницы вверз при изменении url
   * @returns {{x: number, y: number}}
   */
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
