<template>
  <div class="city-selector" v-if="cities.length > 0">
    <div class="city-selector__name" @click="isDropVisible = true">
      {{ cityName }}
    </div>
    <div class="city-selector__arrow" @click="isDropVisible = true">
      <DropDownIcon />
    </div>
    <nav class="city-selector__drop" v-if="isDropVisible">
      <ul class="city-selector-drop">
        <li
          v-for="city in cities"
          :key="city.id"
          class="city-selector-drop__item"
        >
          <a
            class="city-selector-drop__link"
            @click="handleOnSelectCity(city.id)"
          >
            {{ city.title }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import DropDownIcon from "../../assets/icons/CityDropdown.svg";
import { CITIES } from "@/constants";
import { mapGetters, mapMutations } from "vuex";

/**
 * Комонент для выбора города
 */
export default {
  name: "CitySelector",

  components: {
    DropDownIcon,
  },

  data: () => ({
    isDropVisible: false,
  }),

  computed: {
    ...mapGetters("Products", ["cities"]),
    ...mapGetters("City", ["city"]),

    cityName: (vm) => vm.cities.find((city) => city.id === vm.city)?.title,
  },

  methods: {
    ...mapMutations("City", ["SET_CITY"]),

    handleOnSelectCity(cityId) {
      this.isDropVisible = false;
      this.SET_CITY(cityId);
    },
  },

  mounted() {
    document.addEventListener("click", (e) => {
      if (e.target.closest(".city-selector") !== null) return;
      this.isDropVisible = false;
    });
  },

  CITIES,
};
</script>

<style lang="scss">
.city-selector {
  position: relative;
  display: grid;
  grid-template-columns: auto 8px;
  grid-gap: 5px;
  cursor: pointer;
  z-index: 10;

  &__name {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #fff;
  }

  &__arrow {
    align-self: center;
  }

  &__drop {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 15px;
  }

  &-drop {
    background: #ffffff;
    border: 0.5px solid #dbdbdb;
    box-sizing: border-box;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    list-style-type: none;
    margin: 0;
    padding: 0 10px;

    &__item {
      white-space: nowrap;
      border-bottom: 1px solid rgba(219, 219, 219, 0.5);

      &:last-child {
        border: none;
      }
    }

    &__link {
      display: block;
      padding: 12px 10px;
      color: #340f06;
      font-size: 15px;
      line-height: 120%;
    }
  }
}
</style>
