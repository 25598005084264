import Vue from "vue";
import Vuex from "vuex";
import Products from "./modules/products";
import City from "./modules/city";

Vue.use(Vuex);

/**
 * Базовое хранилище
 */
export default new Vuex.Store({
  modules: {
    Products,
    City,
  },
});
