<template>
  <div class="order" v-if="basket.items.length > 0">
    <VHeader class="header--small">
      <button type="button" class="order__close" @click="onBack">
        <CloseIcon />
      </button>
      <button
        type="button"
        class="order__close order__close--clear"
        @click="onClearCart"
      >
        Очистить
      </button>
    </VHeader>
    <Container>
      <h1 class="order__title">
        {{ basket.count }}
        {{ basket.count | plurals(["букет", "букета", "букетов"]) }} от
        {{ basket.sum | number }} ₽
      </h1>
      <Product
        v-for="item in basket.items"
        :key="item.id + item.size"
        :item="item"
      />
      <div class="order__section">Дата и время доставки</div>
      <FormDates v-model="form.date" @change="form.time = []" />
      <Multiselect
        label="Время"
        :items="times"
        v-model="form.time"
        item-key="name"
        item-value="name"
        single
        class="multiselect--inline"
        name="time"
        :errors="errors"
        empty-text="Сегодня вы уже не сможете оформить заказ"
        @change="validateField('time', 'Вы не выбрали время доставки')"
      />
      <div class="order__section order__section--bottom">Адрес доставки</div>
      <form @submit.prevent="onOrder">
        <FormInput
          name="address"
          label="Адрес доставки"
          v-model="form.address"
          :errors="errors"
          @change="validateField('address', 'Заполните это поле')"
        />
        <FormInput
          name="name"
          label="Имя получателя"
          v-model="form.name"
          :errors="errors"
          @change="validateField('name', 'Заполните это поле')"
        />
        <FormInput
          name="phone"
          type="tel"
          label="Номер телефона получателя"
          v-model="form.phone"
          :errors="errors"
          @change="validateField('phone', 'Заполните это поле')"
        />
        <FormInput name="sign" label="Подпись к открытке" v-model="form.sign" />
        <FormInput
          name="comment"
          label="Комментарий к заказу"
          v-model="form.comment"
          @change="validateField('comment', 'Заполните это поле')"
        />

        <div class="order__submit">
          <FormButton
            :class="{ 'button--between': !disabled }"
            submit
            :disabled="disabled"
          >
            <template v-if="!disabled">
              <span>Оформить заказ</span>
              <span>{{ basket.sum | number }} ₽</span>
            </template>
            <LoadingIcon style="width: 30px; height: 30px" v-else />
          </FormButton>
        </div>
      </form>
    </Container>
    <MessageModal v-if="messageText" @close="messageText = false">
      {{ messageText }}
    </MessageModal>
  </div>
  <EmptyCart v-else />
</template>

<script>
import VHeader from "../../components/VHeader";
import Container from "../../components/Container";
import Multiselect from "../../components/Form/Multiselect";
import FormInput from "../../components/Form/FormInput";
import FormButton from "../../components/Form/FormButton";
import FormDates from "../../components/Form/FormDates";
import CloseIcon from "../../assets/icons/Back.svg";
import LoadingIcon from "../../assets/icons/spinner.svg";
import { mapGetters, mapMutations } from "vuex";
import Product from "./Product";
import EmptyCart from "./EmptyCart";
import utilsMixins from "../../mixins/utils";
import {
  TIME_DIAPASONS,
  GRAPHQL_API_URL,
  DEADLINE_ADD_HOURS,
  DEADLINE_ADD_HOURS_DELIVERY,
  CITIES,
} from "@/constants";
import MessageModal from "@/components/MessageModal";

const dayjs = require("dayjs");
require("dayjs/locale/ru");
dayjs.locale("ru");

const defaultForm = {
  time: [],
  date: null,
  address: "",
  name: "",
  phone: "",
  sign: "",
  comment: "",
};

/**
 * Компонен корзины
 */
export default {
  name: "Index",

  mixins: [utilsMixins],

  components: {
    MessageModal,
    EmptyCart,
    Product,
    FormDates,
    FormButton,
    FormInput,
    Multiselect,
    Container,
    VHeader,
    CloseIcon,
    LoadingIcon,
  },

  props: {
    item: Object,
  },

  data: () => ({
    disabled: false,
    form: {
      ...defaultForm,
    },
    errors: {},
    messageText: false,
  }),

  computed: {
    ...mapGetters("Products", ["items", "basket", "cities"]),
    ...mapGetters("City", ["city"]),

    /**
     * Доступные диапазоны времени доставки
     * @returns Array
     */
    times() {
      if (this.form.date?.current) {
        return TIME_DIAPASONS.filter((x) => x.to > +dayjs().hour());
      }
      return TIME_DIAPASONS;
    },
  },

  methods: {
    ...mapMutations("Products", ["UPDATE_CART"]),

    onBack() {
      window.history.back();
    },

    onClearCart() {
      localStorage.setItem("cart", JSON.stringify([]));
      this.UPDATE_CART();
    },

    /**
     * Проверка или в поле нет ошибки
     * @param name
     * @param message
     * @returns {boolean}
     */
    hasErrorInForm(name, message) {
      if (this.form[name].trim() === "") {
        this.errors = {
          ...this.errors,
          [name]: [message],
        };
        return true;
      }
      return false;
    },

    validateField(name, message) {
      const errors = {
        ...this.errors,
      };
      delete errors[name];
      this.errors = errors;
      if (name !== "time") {
        this.hasErrorInForm(name, message);
      } else {
        if (this.form.time.length === 0) {
          this.errors = {
            ...this.errors,
            [name]: [message],
          };
        }
      }
    },

    validate() {
      this.errors = {};
      let isValid = true;

      if (this.hasErrorInForm("name", "Заполните это поле")) {
        isValid = false;
      }

      if (this.hasErrorInForm("address", "Заполните это поле")) {
        isValid = false;
      }

      if (this.hasErrorInForm("phone", "Заполните это поле")) {
        isValid = false;
      }

      if (this.form.time.length === 0) {
        this.errors = {
          ...this.errors,
          time: ["Вы не выбрали время доставки"],
        };
        isValid = false;
      }
      return isValid;
    },

    onOrder() {
      this.disabled = true;

      if (!this.validate()) {
        this.disabled = false;
        return;
      }
      // Формируем данные о корзине
      const composition = this.basket.items
        .map(
          (item) =>
            `Название: ${item.name};Количество: ${item.count};Размер: ${
              item.size || ""
            };Ссылка: ${window.location.hostname}/product/${item.id}`
        )
        .join("|");

      // Отправляем запрос
      this.$axios
        .post("/v3/graphql", this.generateQuery(composition), {
          baseURL: process.env.VUE_APP_CRM_URL || GRAPHQL_API_URL,
        })
        .then(({ data }) => {
          const httpCode = data?.data?.customer?.task?.saveTask?.httpCode;
          this.disabled = false;
          if (+httpCode !== 200) {
            this.messageText = "При оформлении заказа возникла ошибка!";
          } else {
            this.onClearCart();
            this.$nextTick(() => {
              this.$router.push({ name: "complete" });
            });
          }
        })
        .catch(() => {
          this.messageText = "При оформлении заказа возникла ошибка!";
          this.disabled = false;
        });
    },

    /**
     * Генерация GraphQL заполса
     * @param data
     * @returns {{query: string}}
     */
    generateQuery(data) {
      const deadLine = dayjs().add(DEADLINE_ADD_HOURS, "hours");
      const deadlineInfoDelivery = dayjs().add(
        DEADLINE_ADD_HOURS_DELIVERY,
        "hours"
      );
      const deliveryDate = dayjs(this.form.date?.date).format("DD.MM.YYYY");
      const deliveryDateTime = dayjs(this.form.date?.date).format(
        "DD.MM.YYYY HH:mm"
      );
      const deliveryTime = this.form.time[0]?.name;

      let sign = "";
      if (this.form.sign !== "") {
        sign = `
         {fieldName: "postCard",  printableValue: "Да", newValue: "Да", dictionaryOptions: ["0"]}
         {fieldName: "postCardText", newValue: "${this.form.sign}"}`;
      }

      const comments = [];
      comments.push(`Телефон: ${this.form.phone}`);
      if (this.form.comment.trim().length > 0) {
        comments.push(`Комментарий: ${this.form.comment}`);
      }

      const cityId =
        CITIES.find(
          (city) =>
            city.label ===
            this.cities.find((city) => city.id === this.city)?.title
        )?.id ?? -1;

      return {
        query: `
            mutation {
              customer(id: -1) {
                  task  {
                      saveTask(
                          input: {
                              taskTypeId: 268435595,
                              deadline: "${deadLine.format(
                                "DD.MM.YYYY HH:mm"
                              )}",
                              deadlineInfoDelivery: "${deadlineInfoDelivery.format(
                                "DD.MM.YYYY HH:mm"
                              )}",
                              cityId: ${cityId},
                              comments: "${comments.join(";")}",
                              viewInCalendar:true,
                              source: APPLICATION,
                              taskDirector: CUSTOMER,
                              infoViaAssistant: null,
                              marginId: -1,
                              tags: null,
                              fieldValues:
                              [
                                  {fieldName: "dateOfFlowers", printableValue: "${deliveryDate}", newValue: "${deliveryDate}", dtValue: "${deliveryDateTime}"},
                                  {fieldName: "deliveryPlace", newValue: "${
                                    this.form.address
                                  }"},
                                  {fieldName: "composition", newValue: "${data}"},
                                  {fieldName: "deliveryTime", newValue: "${deliveryTime}"},
                                  {fieldName: "to", newValue: "${
                                    this.form.name
                                  }"},
                                 ${sign}
                              ]
                          }
                      )
                      { errors httpCode taskId}
                  }
              }
          }
        `,
      };
    },
  },
};
</script>

<style lang="scss">
.order {
  background: white;
  overflow-y: scroll;

  &__title {
    padding: 15px 0 19px 0;
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    color: #382823;
  }

  &__section {
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #382823;
    margin-bottom: 14px;

    &--bottom {
      margin-bottom: 38px;
    }
  }

  &__submit {
    margin: 60px 0 15px 0;
  }

  &__close {
    position: absolute;
    bottom: 0;
    left: 5px;
    cursor: pointer;
    border: none;
    background: none;

    &--clear {
      height: 34px;
      left: auto;
      right: 15px;
      bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      line-height: 120%;
      color: #c8ad7d;
    }
  }
}
</style>
