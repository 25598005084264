<template>
  <Container>
    <div class="error-page">
      <div class="error-page__type">404</div>
      <div class="error-page__text">Страница не найдена!</div>
      <div>
        <FormButton @click="$router.push({ name: 'home' })">
          К витрине
        </FormButton>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "../components/Container";
import FormButton from "../components/Form/FormButton";

/**
 * Компонент для вывода страницы ошибки
 */
export default {
  name: "404",
  components: { FormButton, Container },
};
</script>

<style lang="scss">
.error-page {
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__type {
    font-size: 32px;
    font-weight: bold;
    line-height: 120%;
    color: #340f06;
    margin-bottom: 12px;
  }
  &__text {
    font-size: 15px;
    line-height: 120%;
    color: #340f06;
    margin-bottom: 26px;
  }
}
</style>
