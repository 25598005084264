<template>
  <div class="modal">
    <div class="modal__overlay"></div>
    <div class="modal__wrapper">
      <div class="modal__container">
        <div class="modal__head">
          <div class="modal__title">{{ title }}</div>
          <button class="modal__close" @click="$emit('close')">
            <CloseIcon />
          </button>
        </div>
        <div class="modal__body">
          <div class="modal__content">
            <slot></slot>
          </div>
          <div class="modal__footer">
            <Container>
              <slot name="footer"></slot>
            </Container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "../assets/icons/Close.svg";
import Container from "./Container";
import modalMixins from "../mixins/modal";

/**
 * Компонент обертка модального окна
 */
export default {
  name: "Modal",

  mixins: [modalMixins],

  components: {
    Container,
    CloseIcon,
  },

  props: {
    /**
     * Заголовок модального окна
     */
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 15, 6, 0.5);
  }

  &__wrapper {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 44px;
  }

  &__container {
    background: white;
    height: 100%;
    border-radius: 20px 20px 0 0;
    position: relative;
  }

  &__head {
    padding: 16px 15px 24px 15px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    color: #382823;
  }

  &__close {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    width: 44px;
    height: 44px;
    background: none;
    border: none;
    top: 5px;
    right: 15px;
  }

  &__body {
    display: grid;
    grid-template-rows: 1fr auto;
    height: calc(100% - 79px);
  }
}
</style>
