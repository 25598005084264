<template>
  <div class="product-text">
    <div class="product-text__title">{{ title }}</div>
    <div class="product-text__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * Компонент для вывода текста описания в товаре
 */
export default {
  name: "ProductText",

  props: {
    /**
     * Заголовок блока
     */
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.product-text {
  margin-bottom: 25px;

  &__title {
    font-size: 12px;
    line-height: 120%;
    color: #808080;
    margin-bottom: 6px;
  }

  &__text {
    font-size: 15px;
    line-height: 120%;
    color: #340f06;

    * {
      font-size: 15px;
      line-height: 120%;
      color: #340f06;
    }

    p {
      margin-bottom: 15px;
    }
  }
}
</style>
