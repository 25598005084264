<template>
  <Container>
    <div class="order-empty">
      <div class="order-empty__text">У вас в корзине нет товара!</div>
      <div>
        <FormButton @click="$router.push({ name: 'home' })">
          К витрине
        </FormButton>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "../../components/Container";
import FormButton from "../../components/Form/FormButton";

/**
 * Компонент отображаюший что в корзине нет товара
 */
export default {
  name: "EmptyCart",
  components: { FormButton, Container },
};
</script>

<style lang="scss">
.order-empty {
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 15px;
    line-height: 120%;
    color: #340f06;
    margin-bottom: 26px;
  }
}
</style>
