<template>
  <div class="dates">
    <div
      class="dates__item"
      :class="{ active: active === item }"
      v-for="item in items"
      :key="item.id"
      @click="active = item"
    >
      <div class="dates__week">{{ item.dayOfWeek }}</div>
      <div class="dates__day">{{ item.day }}</div>
      <div class="dates__current" v-if="item.current && active !== item"></div>
      <div class="dates__month" v-if="active === item">
        {{ item.month }}
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require("dayjs");
require("dayjs/locale/ru");
dayjs.locale("ru");

import { DAYS_IN_LIST } from "@/constants";

/**
 * Компонент для отображения даты для заказа товара
 */
export default {
  name: "FormDates",

  data: () => ({
    items: [],
  }),

  props: {
    /**
     * Значение которое пришло из v-model
     */
    value: {
      type: Object,
      default: null,
    },
  },

  computed: {
    active: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("change");
      },
    },
  },

  created() {
    const date = dayjs();
    this.items.push({
      current: true,
      dayOfWeek: date.format("dd"),
      day: date.format("DD"),
      month: date.format("MMM"),
      date: date.format(),
    });
    for (let i = 1; i < DAYS_IN_LIST; i++) {
      const date = dayjs().add(i, "day");
      this.items.push({
        current: false,
        dayOfWeek: date.format("dd"),
        day: date.format("DD"),
        month: date.format("MMM"),
        date: date.format(),
      });
    }
    this.active = this.items[0];
  },
};
</script>

<style lang="scss">
.dates {
  width: calc(100% + 15px);
  display: flex;
  overflow-x: scroll;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    width: 44px;
    min-width: 44px;
    height: 65px;
    margin-right: 3px;

    &.active {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
  }

  &__week {
    padding: 8px 0 3px 0;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.006em;
    color: #340f06;
    text-align: center;
  }

  &__day {
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: #340f06;
  }

  &__current {
    width: 4px;
    height: 4px;
    margin: 5px auto 0 auto;
    background: #aa8e58;
    border-radius: 50%;
  }

  &__month {
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.006em;
    color: #340f06;
    text-align: center;
  }
}
</style>
