<template>
  <div class="cart-button" v-if="basket.items.length > 0">
    <FormButton @click="$router.push({ name: 'cart' })">
      <div class="cart-button-data">
        <div class="cart-button-data__label">Корзина</div>
        <div class="cart-button-data__count">
          {{ basket.count }}
          {{ basket.count | plurals(["букет", "букета", "букетов"]) }}
        </div>
        <div class="cart-button-data__sum">{{ basket.sum | number }} ₽</div>
      </div>
    </FormButton>
  </div>
</template>

<script>
import FormButton from "../../components/Form/FormButton";
import { mapGetters } from "vuex";
import utilsMixins from "../../mixins/utils";

/**
 * Компонент для вывода кнопки корзины внизу экрана
 */
export default {
  name: "FloatCartButton",

  mixins: [utilsMixins],

  components: { FormButton },

  computed: {
    ...mapGetters("Products", ["basket"]),
  },
};
</script>

<style lang="scss">
.cart-button {
  position: fixed;
  z-index: 98;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  filter: drop-shadow(0px -5px 20px rgba(0, 0, 0, 0.1));
  background: white;

  &-data {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
    width: 100%;

    &__label {
      font-size: 16px;
      line-height: 125%;
      color: white;
      align-self: center;
    }

    &__count {
      text-align: left;
      align-self: center;
      font-size: 16px;
      line-height: 125%;
      color: rgba(255, 255, 255, 0.5);
    }

    &__sum {
      font-size: 16px;
      line-height: 125%;
      color: #ffffff;
    }
  }
}
</style>
