<template>
  <div class="product-button">
    <div class="product-button__counter">
      <Counter v-model="count" />
    </div>
    <div class="product-button__add">
      <FormButton class="button--between" @click="onAddToCart">
        <span>В корзину</span>
        <span>{{ (price * count) | number }} ₽</span>
      </FormButton>
    </div>
  </div>
</template>

<script>
import Counter from "../../components/Form/Counter";
import FormButton from "../../components/Form/FormButton";
import utilsMixins from "../../mixins/utils";
import { mapMutations } from "vuex";

/**
 * Компонент для вывода панели добавления товара в корзину
 */
export default {
  name: "ProductAddToCart",

  mixins: [utilsMixins],

  components: { FormButton, Counter },

  props: {
    /**
     * Изображение товара
     */
    image: {
      type: String,
      default: "",
    },
    /**
     * Название товара
     */
    name: {
      type: String,
      default: "",
    },
    /**
     * Цена товара
     */
    price: {
      type: Number,
      default: 0,
    },
    /**
     * Идентификатор товара
     */
    id: {
      type: String,
      default: "",
    },
    /**
     * Размер товара
     */
    size: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    count: 1,
  }),

  methods: {
    ...mapMutations("Products", ["UPDATE_CART"]),

    onAddToCart() {
      this.UPDATE_CART({
        id: this.id,
        count: this.count,
        size: this.size,
      });
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss">
.product-button {
  position: fixed;
  z-index: 30;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  height: 74px;
  padding: 15px;
  &:after {
    display: block;
    width: 100%;
    position: absolute;
    content: "";
    height: 60px;
    top: -60px;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
}
</style>
