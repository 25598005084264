<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
/**
 * Компонент базовый контейнер для равных отступов
 */
export default {
  name: "Container",
};
</script>

<style lang="scss">
.container {
  padding: 0 15px;
}
</style>
