/**
 * Ссылка на API бэка с цветами
 * @type {string}
 */
const API_URL = "https://primeservices.technolab.com.ru";

/**
 * Тоекен для доступа к API_URL
 * @type {string}
 */
const X_APP_TOKEN = "928ce0cf-adbe-4ab7-ab3e-76bbf0f78fb3";

/**
 * Ссылка на сервис на который слать GraphQL запросы
 * @type {string}
 */
const GRAPHQL_API_URL = "https://demo.primeconcept.co.uk";

/**
 * Количество дне которые выводим в календаре
 * @type {number}
 */
const DAYS_IN_LIST = 31;

/**
 * Сколько часов добавлять к дедлайну
 * @type {number}
 */
const DEADLINE_ADD_HOURS = 2;

/**
 * Сколько часов добавлять к дедлайну доставки
 * @type {number}
 */
const DEADLINE_ADD_HOURS_DELIVERY = 3;

/**
 * Доступные для заказа диапазоны времени
 * @type Array
 */
const TIME_DIAPASONS = [
  {
    name: "08:00 - 11:00",
    to: 8,
  },
  {
    name: "11:00 - 14:00",
    to: 11,
  },
  {
    name: "14:00 - 17:00",
    to: 14,
  },
  {
    name: "17:00 - 20:00",
    to: 17,
  },
  {
    name: "20:00 - 23:00",
    to: 20,
  },
];

const CITIES = [
  {
    id: 76,
    label: "Москва",
  },
  {
    id: 90,
    label: "Лондон",
  },
  {
    id: 1858,
    label: "Санкт-Петербург",
  },
  {
    id: 1859,
    label: "Сочи",
  },
];

export {
  API_URL,
  DAYS_IN_LIST,
  X_APP_TOKEN,
  TIME_DIAPASONS,
  GRAPHQL_API_URL,
  DEADLINE_ADD_HOURS,
  DEADLINE_ADD_HOURS_DELIVERY,
  CITIES,
};
