<template>
  <div class="filter">
    <button class="filter__switcher" type="button" @click="modal = true">
      <FilterIcon />
    </button>
    <Modal title="Фильтр" v-if="modal" @close="modal = false">
      <template>
        <Container>
          <Multiselect
            label="Цветы"
            v-model="form.flowers"
            :items="types"
            item-key="id"
            item-value="title"
          />
          <div class="filter__label">Стоимость, ₽</div>
          <div class="filter__diapasons">
            <div class="filter__diapason">{{ form.prices[0] | number }}</div>
            <div class="filter__diapason">{{ form.prices[1] | number }}</div>
          </div>
          <div class="filter__slider">
            <VueSlider
              v-model="form.prices"
              :process="true"
              :min="prices[0]"
              :max="prices[1]"
            />
          </div>
        </Container>
      </template>
      <template #footer>
        <div class="filter-buttons">
          <div class="filter-buttons__row">
            <FormButton class="button--invert" @click="onReset">
              Сбросить
            </FormButton>
          </div>
          <div class="filter-buttons__row">
            <FormButton @click="onFilter">Готово</FormButton>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import FilterIcon from "../../assets/icons/Filter.svg";
import Modal from "../../components/Modal";
import FormButton from "../../components/Form/FormButton";
import Multiselect from "../../components/Form/Multiselect";
import Container from "../../components/Container";
import { mapGetters, mapMutations } from "vuex";
import utilsMixins from "../../mixins/utils";

const defaultForm = {
  flowers: [],
  sizes: [],
  prices: [0, 20],
};

/**
 * Компонент для выбора фильтра товаров
 */
export default {
  name: "TheFilter",

  mixins: [utilsMixins],

  components: {
    Container,
    Multiselect,
    FormButton,
    Modal,
    FilterIcon,
    VueSlider,
  },

  data: () => ({
    modal: false,
    form: {
      ...defaultForm,
    },
  }),

  computed: {
    ...mapGetters("Products", ["filter", "types", "prices"]),
  },

  methods: {
    ...mapMutations("Products", [
      "SELECT_TYPES",
      "SET_RANGES",
      "FILTER_PRODUCTS",
    ]),

    onReset() {
      this.form.flowers = [];
      this.SELECT_TYPES(this.form.flowers);
      this.form.prices = this.prices;
      this.SET_RANGES(this.form.prices);

      this.$nextTick(() => {
        this.FILTER_PRODUCTS();
      });
    },

    onFilter() {
      this.SELECT_TYPES(this.form.flowers);
      this.SET_RANGES(this.form.prices);
      this.modal = false;

      this.$nextTick(() => {
        this.FILTER_PRODUCTS();
      });
    },
  },

  created() {
    this.form.prices = this.filter.prices;

    if (this.filter.types.length > 0) {
      this.form.flowers = this.filter.types;
    }
  },
};
</script>

<style lang="scss">
.filter {
  &__switcher {
    width: 44px;
    height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
  }

  &-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  &__label {
    font-size: 12px;
    line-height: 120%;
    color: #808080;
    margin-bottom: 11px;
  }

  &__diapasons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__diapason {
    font-size: 15px;
    line-height: 120%;
    color: #340f06;
  }

  &__slider {
    padding: 0 7px;
  }
}
.vue-slider-rail {
  height: 2px !important;
}
.vue-slider-process {
  background: #c8ad7d !important;
}
.vue-slider-dot-tooltip {
  display: none !important;
}
.vue-slider-dot-handle {
  box-shadow: none !important;
  border: 2px solid #c8ad7d !important;
}
</style>
