<template>
  <div class="multiselect" :class="{ 'multiselect--error': error }">
    <div class="multiselect__label">{{ label }}</div>
    <div v-if="items.length" class="multiselect__items">
      <button
        v-for="item in items"
        :key="item[itemKey]"
        class="multiselect__item"
        type="button"
        @click="onSelect(item)"
        :class="{ active: isActive(item) }"
      >
        {{ item[itemValue] }}
        <span v-if="item.desc !== undefined">
          {{ item.desc }}
        </span>
      </button>
    </div>
    <div v-else class="multiselect__empty">{{ emptyText }}</div>
    <ul v-if="error" class="multiselect__errors">
      <li v-for="errorText in errors[name]" :key="errorText">
        {{ errorText }}
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * Компонент для выбора значений
 */
export default {
  name: "Multiselect",

  props: {
    /**
     * Метка поля
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Поле которое используеться в качестве ключа
     */
    itemKey: {
      type: String,
      default: "",
    },
    /**
     * Поле которое используеться в качестве значения
     */
    itemValue: {
      type: String,
      default: "",
    },
    /**
     * Значение которое пришло из v-model
     */
    value: Array,
    /**
     * Значения
     */
    items: Array,
    /**
     * Запрет множественного выбора
     */
    single: {
      type: Boolean,
      default: false,
    },
    /**
     * Объект с массивом ошибок
     */
    errors: {
      type: Object,
      default: null,
    },
    /**
     * Имя поля
     */
    name: {
      type: String,
      default: "",
    },
    /**
     * Текст который отобразиться если в поле items количество элементов будет равно нулю
     */
    emptyText: {
      type: String,
      default: "",
    },
  },

  computed: {
    error: (vm) => vm.errors && vm.errors[vm.name],
  },

  methods: {
    isActive(value) {
      return this.value.find((x) => x[this.itemKey] === value[this.itemKey]);
    },

    onSelect(item) {
      if (this.single) {
        this.$emit("input", [item]);
        this.$emit("change");
        return;
      }
      const values = [...this.value];
      const index = values.findIndex(
        (x) => x[this.itemKey] === item[this.itemKey]
      );
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(item);
      }
      this.$emit("input", values);
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  margin-bottom: 20px;

  &__label {
    font-size: 12px;
    line-height: 120%;
    color: #808080;
    margin-bottom: 12px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    background: #ffffff;
    border: 0.5px solid #c8ad7d;
    border-radius: 25px;
    height: 30px;
    font-size: 13px;
    color: #aa8e58;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
    margin: 0 5px 12px 0;

    &.active {
      color: white;
      background: #c8ad7d;

      span {
        color: white;
      }
    }

    &--error {
      &__label {
        color: red;
      }
    }

    span {
      font-size: 10px;
      line-height: 120%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.006em;
      color: #808080;
      margin-top: 2px;
    }
  }

  &--large & {
    &__item {
      height: 40px;
      border-radius: 5px;
    }
  }

  &--inline & {
    &__items {
      flex-wrap: nowrap;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      white-space: nowrap;
      min-width: min-content;
    }
  }

  &__errors {
    list-style-type: none;
    padding: 0;
    margin-top: 8px;

    li {
      font-size: 11px;
      line-height: 1.14;
      color: red;
      margin-bottom: 5px;
    }
  }

  &__empty {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: red;
  }
}
</style>
