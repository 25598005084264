<template>
  <a class="card" @click="$emit('openProduct')">
    <div class="card-head">
      <div class="card-head__title">{{ name }}</div>
      <div class="card-head__desc">{{ description }}</div>
      <div class="card-head__price">от {{ price | number }} <span>₽</span></div>
    </div>
    <div class="card__image">
      <img :src="image" :alt="name" />
      <div class="card__badge" v-if="badge !== ''">
        <Badge>{{ badge }}</Badge>
      </div>
    </div>
  </a>
</template>

<script>
import Badge from "./Badge";
import utilsMixins from "../mixins/utils";

/**
 * Компонент карты товара
 */
export default {
  name: "Card",

  mixins: [utilsMixins],

  components: { Badge },

  props: {
    /**
     * Идентификатор товара
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Название товара
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Описание товара
     */
    description: {
      type: String,
      required: true,
    },
    /**
     * Цена товара
     */
    price: {
      type: Number,
      required: true,
    },
    /**
     * Бейдж товара
     */
    badge: {
      type: String,
      default: "",
    },

    /**
     * Изображение товара
     */
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  text-decoration: none;
  display: block;

  &-head {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 10px;
    grid-template-areas: "card-title card-price" "card-desc card-price";
    grid-row-gap: 2px;

    &__title {
      grid-area: card-title;
      font-size: 14px;
      line-height: 120%;
      color: #340f06;
    }

    &__desc {
      grid-area: card-desc;
      font-size: 10px;
      line-height: 120%;
      letter-spacing: 0.006em;
      color: #808080;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__price {
      grid-area: card-price;
      font-size: 15px;
      line-height: 120%;
      color: #340f06;
      white-space: nowrap;
      align-self: center;
      font-weight: 500;
    }
  }

  &__image {
    position: relative;
    height: 200px;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  &__badge {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
</style>
