<template>
  <div class="message-modal">
    <div class="message-modal__body">
      <div class="message-modal__text">
        <slot></slot>
      </div>
      <FormButton @click="$emit('close')">Закрыть</FormButton>
    </div>
  </div>
</template>

<script>
import FormButton from "@/components/Form/FormButton";

export default {
  name: "MessageModal",

  components: { FormButton },

  created() {
    document.getElementsByTagName("html")[0].classList.add("page-modal");
  },

  destroyed() {
    document.getElementsByTagName("html")[0].classList.remove("page-modal");
  },
};
</script>

<style lang="scss">
.message-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(233, 233, 233, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  &__body {
    padding: 15px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
  }

  &__text {
    font-size: 16px;
    line-height: 125%;
    color: #340f06;
    margin-bottom: 15px;
  }
}
</style>
