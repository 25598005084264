/**
 * Хранилище города
 */

export default {
  namespaced: true,

  state: {
    city: 0,
  },

  mutations: {
    SET_CITY: (state, payload) => {
      state.city = payload;
      sessionStorage.setItem("city", payload);
    },
  },

  getters: {
    city: (state) => state.city,
  },
};
