<template>
  <div class="home">
    <VHeader>
      <div class="header-filter">
        <Tags />
        <FilterPanel />
      </div>
    </VHeader>
    <Container v-if="products.length > 0" class="home__items">
      <Card
        v-for="item in filteredProducts"
        :key="item._id"
        :id="item._id"
        :name="item.name"
        :image="item.image"
        :description="item.description"
        :price="+item.price"
        @openProduct="product = item"
      />
    </Container>
    <Container v-else class="home__empty">
      По вашему запросу ничего не найдено
    </Container>
    <transition name="fade">
      <ProductModal @hide="product = null" v-if="product" :item="product" />
    </transition>
    <FloatCartButton />
  </div>
</template>

<script>
import VHeader from "../../components/VHeader";
import FilterPanel from "./TheFilter";
import Tags from "../../components/Filter/Tags";
import Card from "../../components/Card";
import Container from "../../components/Container";
import { mapGetters } from "vuex";
import ProductModal from "./ProductModal";
import FloatCartButton from "./FloatCartButton";

/**
 * Компонент для вывода главной страницы
 */
export default {
  name: "Index",

  components: {
    FloatCartButton,
    ProductModal,
    Container,
    Card,
    Tags,
    FilterPanel,
    VHeader,
  },

  data: () => ({
    filteredItems: [],
    product: null,
  }),

  computed: {
    ...mapGetters("Products", ["products", "items"]),
    ...mapGetters("City", ["city"]),

    filteredProducts: (vm) =>
      vm.products.filter((product) => product.cities_ids.includes(vm.city)),
  },

  mounted() {
    // Если передан id продукта то присваиваем его продукту и он выведеться
    this.product = this.items.find((x) => x._id === this.$route.params.id);
  },
};
</script>

<style lang="scss">
.home {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  min-height: 100vh;

  &__items {
    padding-bottom: 80px;
  }

  &__empty {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 200px;
  }
}

.header-filter {
  display: grid;
  grid-template-columns: 1fr 34px;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}

.fade-enter,
.fade-leave-to {
  transform: translateY(100%);
}
</style>
