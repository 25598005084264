<template>
  <div class="loader">
    <img :src="image" alt="" />
  </div>
</template>

<script>
import SpinnerImage from "../assets/images/spinner.gif";

/**
 * Компонент для отображение процесса загрузки страницы
 */
export default {
  name: "Loader",

  data: () => ({
    image: SpinnerImage,
  }),
};
</script>

<style lang="scss">
.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
