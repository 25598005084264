<template>
  <div class="badge">
    <slot></slot>
  </div>
</template>

<script>
/**
 * Компонент для вывода бейджика
 */
export default {
  name: "Badge",
};
</script>

<style lang="scss">
.badge {
  height: 23px;
  background: linear-gradient(90deg, #ac905f 0%, #c8ad7d 100%);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 0.006em;
  color: #ffffff;
}
</style>
