<template>
  <div class="counter">
    <button class="counter__button" type="button" @click="onDecrease">
      <MinusIcon />
    </button>
    <div class="counter__value">{{ value }}</div>
    <button class="counter__button" type="button" @click="onIncrease">
      <PlusIcon />
    </button>
  </div>
</template>

<script>
import MinusIcon from "../../assets/icons/MinusButton.svg";
import PlusIcon from "../../assets/icons/PlusButton.svg";

/**
 * Компонент счетчик для изменения количества товара
 */
export default {
  name: "Counter",

  props: {
    /**
     * Текущее количество в счетчике
     */
    value: {
      type: Number,
      default: 1,
    },

    /**
     * этот проп указывает или можно менять в ноль количество товара, используеться в корзине
     * @values true | false
     */
    allowEdit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MinusIcon,
    PlusIcon,
  },

  methods: {
    /**
     * Уменьшение количества
     */
    onDecrease() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
      } else if (this.allowEdit && this.value === 1) {
        this.$emit("input", this.value - 1);
      }
      this.$emit("change");
    },

    /**
     * Увеличение количества
     */
    onIncrease() {
      this.$emit("input", this.value + 1);
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss">
.counter {
  display: grid;
  grid-template-columns: 44px 20px 44px;
  grid-gap: 5px;

  &__value {
    align-self: center;
    font-size: 16px;
    line-height: 125%;
    color: #340f06;
    text-align: center;
  }

  &__button {
    cursor: pointer;
    width: 44px;
    height: 44px;
    background: none;
    border: none;
  }
}
</style>
