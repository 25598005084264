<template>
  <div class="product">
    <div class="product__head">
      <button @click="$emit('hide')" class="product__close">
        <CloseLargeIcon />
      </button>
      <Slider :images="item.images" @openPreview="openPreview" />
      <div class="product__badge" v-if="item.prime">
        <Badge>PRIME</Badge>
      </div>
    </div>
    <Container class="product__body">
      <h1 class="product__title">{{ item.name }}</h1>
      <div class="product__desc">{{ item.description }}</div>
      <Multiselect
        label="Размер букета"
        v-model="selectedSizes"
        :items="sizes"
        item-key="name"
        item-value="name"
        single
        class="multiselect--large"
        v-if="sizes.length > 0"
      />

      <productText title="Доставка">
        {{ item.delivery }}
      </productText>
      <productText title="Особенности" hidden></productText>
    </Container>
    <ProductAddToCart
      :image="item.image"
      :name="item.name"
      :price="price"
      :id="item._id"
      ref="productAdd"
      :size="selectedSize"
      @hide="$emit('hide')"
    />
    <PreviewImage
      v-if="imageUrl !== false"
      :image-url="imageUrl"
      @close="imageUrl = false"
    />
  </div>
</template>

<script>
import Badge from "../../components/Badge";
import CloseLargeIcon from "../../assets/icons/CloseLarge.svg";
import Container from "../../components/Container";
import Multiselect from "../../components/Form/Multiselect";
import ProductText from "./ProductText";
import { mapGetters } from "vuex";
import Slider from "./Slider";
import ProductAddToCart from "./ProductAddToCart";
import PreviewImage from "./PreviewImage";
import modalMixins from "../../mixins/modal";

/**
 * Компонент для отображения товара в модальном окне
 */
export default {
  name: "ProductModal",

  mixins: [modalMixins],

  components: {
    PreviewImage,
    ProductAddToCart,
    Slider,
    ProductText,
    Multiselect,
    Container,
    Badge,
    CloseLargeIcon,
  },

  props: {
    /**
     * Данные товара
     */
    item: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    imageUrl: false,
    modal: false,
    selectedSizes: [],
    count: 1,
  }),

  computed: {
    ...mapGetters("Products", ["items"]),

    sizes() {
      if (this.item.composition.length > 0) {
        return this.item.composition
          .filter((item) => item.price)
          .map((item) => ({
            name: item.size,
            desc: `${item.price.toLocaleString("ru-RU")} ₽`,
            price: item.price,
          }));
      }
      return [];
    },

    price() {
      if (this.sizes.length > 0) {
        return this.selectedSizes.length > 0
          ? this.selectedSizes[0].price
          : +this.sizes[0].price;
      }

      return +this.item.price;
    },

    selectedSize() {
      if (this.sizes.length > 0) {
        return this.selectedSizes.length > 0
          ? this.selectedSizes[0].name
          : this.sizes[0].name;
      }
      return null;
    },
  },

  methods: {
    openPreview(imageUrl) {
      this.imageUrl = imageUrl;
    },
  },

  created() {
    if (this.item.composition.length > 0) {
      const item = this.item.composition[0];
      if (item.price) {
        this.selectedSizes.push({
          name: item.size,
          desc: `${item.price.toLocaleString("ru-RU")} ₽`,
          price: item.price,
        });
      }
    }
  },
};
</script>

<style lang="scss">
.product {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: scroll;
  &__head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 375px;
    z-index: 1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__badge {
    position: absolute;
    bottom: 20px;
    left: 10px;
    z-index: 20;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 99;
    display: block;
    width: 44px;
    height: 44px;
    background: none;
    border: none;
  }

  &__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    color: #340f06;
    margin-bottom: 5px;
  }

  &__desc {
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;
    color: #808080;
    margin-bottom: 25px;
  }

  &-detail {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 10px;

    &__image {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 15px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__row {
      font-size: 15px;
      line-height: 120%;
      color: #340f06;
      align-self: center;
    }
  }

  &__body {
    transform: translateY(360px);
    position: relative;
    z-index: 20;
    background: white;
    padding-top: 15px;
    border-radius: 20px 20px 0 0;
    padding-bottom: 74px;
  }
}
</style>
