<template>
  <div class="form-input__wrapper">
    <div class="form-input" :class="{ 'form-input--error': error }">
      <label
        class="form-input__label"
        :for="name"
        :class="{ focused: isFocused }"
      >
        {{ label }}
      </label>
      <input
        :required="required"
        class="form-input__control"
        :type="type"
        v-model="currentValue"
        :id="name"
        @focus="focused = true"
        @blur="focused = false"
      />
    </div>
    <ul v-if="error" class="form-input__errors">
      <li v-for="errorText in errors[name]" :key="errorText">
        {{ errorText }}
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * Компонент обертка для однострочного текстового поля
 */
export default {
  name: "FormInput",

  props: {
    /**
     * Метка поля
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Значение которое пришло из v-model
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Имя поля
     */
    name: {
      type: String,
      default: "",
    },
    /**
     * Тип поля
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Обязательное поле или нет
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Объект с массивом ошибок
     */
    errors: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    focused: false,
  }),

  computed: {
    isFocused: (vm) => vm.focused || vm.currentValue.length > 0,

    error: (vm) => vm.errors && vm.errors[vm.name],

    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("change");
      },
    },
  },
};
</script>

<style lang="scss">
.form-input {
  position: relative;
  height: 40px;
  border-bottom: 0.5px solid #dbdbdb;

  &__wrapper {
    margin-bottom: 20px;
  }

  &__label {
    position: absolute;
    top: 4px;
    font-size: 15px;
    line-height: 120%;
    color: #340f06;
    transition: 0.3s;

    &.focused {
      top: -6px;
      font-size: 11px;
    }
  }

  &__control {
    display: block;
    height: 100%;
    border: none;
    width: 100%;
    font-size: 15px;
    line-height: 120%;
    color: #340f06;
  }

  &--error & {
    border-bottom-color: red;

    &__label {
      color: red;
    }

    &__control {
      color: red;
    }
  }

  &__errors {
    list-style-type: none;
    padding: 0;
    margin-top: 8px;

    li {
      font-size: 11px;
      line-height: 1.14;
      color: red;
      margin-bottom: 5px;
    }
  }
}
</style>
