/**
 * Вспомогательные миксины
 */
export default {
  filters: {
    /**
     * Функция для форматировния в удобочитаемый вариант число
     * @param val
     * @returns {string}
     */
    number: (val) => val.toLocaleString("ru-RU"),

    /**
     * Функция для склонения слов
     * @param n количество чего либо
     * @param titles массив с вариантами например [товар, товара, товаров]
     * @returns {*}
     */
    plurals: (n, titles) =>
      titles[
        n % 10 === 1 && n % 100 !== 11
          ? 0
          : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? 1
          : 2
      ],
  },
};
