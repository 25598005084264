<template>
  <div class="product-slider">
    <div ref="slider" class="keen-slider">
      <div
        v-for="image in allImages"
        :key="image.source.image"
        class="keen-slider__slide"
      >
        <div
          class="product-slider__item"
          @click="$emit('openPreview', image.source.image)"
        >
          <img :src="image.source.image" alt="" />
        </div>
      </div>
    </div>
    <div v-if="slider && allImages.length > 1" class="product-slider__dots">
      <button
        v-for="(image, idx) in images"
        @click="slider.moveToSlideRelative(idx)"
        :class="{ active: current === idx }"
        :key="idx"
      ></button>
    </div>
  </div>
</template>

<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

/**
 * Комопонен для вывода слайдера товаров
 */
export default {
  name: "Slider",

  props: {
    /**
     * Список изображений
     */
    images: Array,
  },

  data: () => ({
    slider: null,
    current: 1,
  }),

  computed: {
    allImages: (vm) => vm.images.slice(0, 1),
  },

  mounted() {
    // Инициализация слайдера
    this.slider = new KeenSlider(this.$refs.slider, {
      loop: true,
      slideChanged: (s) => {
        this.current = s.details().relativeSlide;
      },
    });
  },

  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
};
</script>

<style lang="scss">
.product-slider {
  position: relative;

  &__item {
    width: 100%;
    height: 375px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__dots {
    position: absolute;
    width: 100%;
    left: 0;
    top: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;

    button {
      width: 11px;
      height: 11px;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
      &:after {
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}
</style>
