<template>
  <div class="complete">
    <Container class="complete__body">
      <div class="complete__icon">
        <CompleteIcon />
      </div>
      <div class="complete__text">Спасибо,<br />Ваш заказ оформлен</div>
      <div class="complete__desc">
        В ближайшее время с Вами свяжется менеджер
      </div>
    </Container>
    <Container class="complete__footer">
      <FormButton @click="$router.push({ name: 'home' })">
        Вернуться к витрине
      </FormButton>
    </Container>
  </div>
</template>

<script>
import FormButton from "../../components/Form/FormButton";
import Container from "../../components/Container";
import CompleteIcon from "../../assets/icons/Complete.svg";

/**
 * Компонент для вывода страницы о том ччто заказ успешно оформлен
 */
export default {
  name: "Index",
  components: { Container, FormButton, CompleteIcon },
};
</script>

<style lang="scss">
.complete {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  background: white;
  width: 100%;

  &__footer {
    padding: 15px;
  }

  &__body {
    align-self: center;
  }

  &__icon {
    text-align: center;
    margin-bottom: 20px;
  }

  &__text {
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    text-align: center;
    color: #340f06;
    margin-bottom: 15px;
  }

  &__desc {
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: #808080;
  }
}
</style>
