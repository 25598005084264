/**
 * Миксины для модального окна
 */
export default {
  created() {
    document.getElementsByTagName("html")[0].classList.add("page-modal");
  },

  destroyed() {
    document.getElementsByTagName("html")[0].classList.remove("page-modal");
  },
};
