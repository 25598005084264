<template>
  <div class="tags">
    <div
      class="tag"
      v-for="category in categories"
      :key="category.id"
      :style="style(category)"
      @click="onSelectCategory(category)"
      :class="{ active: isActive(category), 'tag--no-image': !category.image }"
    >
      <div class="tag__overlay">
        <div class="tag__name">
          {{ category.name }}
        </div>
        <div class="tag__count">
          {{ category.count }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Tags",

  computed: {
    ...mapGetters("Products", ["categories", "filter"]),
  },

  methods: {
    ...mapMutations("Products", ["SELECT_CATEGORY", "FILTER_PRODUCTS"]),

    isActive(category) {
      return category.id === this.filter.category?.id;
    },

    style(category) {
      if (category.image) {
        return {
          backgroundImage: `url(${category.image})`,
        };
      }
    },

    onSelectCategory(category) {
      this.SELECT_CATEGORY(category);
      this.$nextTick(() => {
        this.FILTER_PRODUCTS();
      });
    },
  },
};
</script>

<style lang="scss">
.tags {
  display: flex;
  overflow: scroll;
  align-self: center;
  &::-webkit-scrollbar {
    display: none;
  }
}

.tag {
  position: relative;
  height: 35px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  min-width: min-content;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;

  &__overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    padding: 3px 20px 0 20px;
    height: 100%;
    border-radius: 8px;
  }

  &__name {
    text-align: center;
    white-space: nowrap;
    color: white;
    font-size: 12px;
    line-height: 120%;
  }

  &__count {
    text-align: center;
    white-space: nowrap;
    color: white;
    font-size: 10px;
    line-height: 120%;
    margin: auto;
    width: 25px;
  }

  &.active {
    .tag {
      &__count {
        border-bottom: 1px solid white;
      }
    }
  }

  &--no-image {
    .tag {
      &__overlay {
        background: rgba(52, 15, 6, 0.1);
        border: 1px solid #fff;
      }
    }
  }
}
</style>
