<template>
  <div class="order-product">
    <div class="order-product__row">
      <div class="order-product__image">
        <img :src="item.image" alt="" />
      </div>
    </div>
    <div class="order-product__row">
      <div class="order-product__name">
        {{ item.name }}
        <template v-if="item.size">({{ item.size }})</template>
      </div>
      <div class="order-product__desc">
        {{ item.description }}
      </div>
      <div class="order-product__controls">
        <div class="order-product__price">
          {{ (item.price * item.count) | number }} ₽
        </div>
        <Counter v-model="item.count" @change="onChange" allowEdit />
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "../../components/Form/Counter";
import utilsMixins from "../../mixins/utils";
import { mapMutations } from "vuex";

/**
 * Компонент для вывода товара добавленного в корзину
 */
export default {
  name: "Product",

  mixins: [utilsMixins],

  components: { Counter },

  props: {
    /**
     * Данные о товаре
     */
    item: {
      type: Object,
      default: null,
    },
  },

  methods: {
    ...mapMutations("Products", ["UPDATE_CART"]),

    onChange() {
      const cartData = JSON.parse(localStorage.getItem("cart"));
      const foundIndex = cartData.findIndex(
        (x) => x.id === this.item.id && x.size === this.item.size
      );
      if (foundIndex >= -1) {
        cartData[foundIndex].count = this.item.count;
      }
      localStorage.setItem("cart", JSON.stringify(cartData));
      this.UPDATE_CART();
    },
  },
};
</script>

<style lang="scss">
.order {
  &-product {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 10px;
    border-bottom: 0.5px solid #dbdbdb;
    padding-bottom: 19px;
    margin-bottom: 25px;

    &__image {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 15px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      font-size: 15px;
      line-height: 120%;
      display: flex;
      align-items: center;
      color: #340f06;
      margin-bottom: 5px;
    }

    &__desc {
      font-size: 12px;
      line-height: 120%;
      display: flex;
      align-items: center;
      color: #808080;
    }

    &__controls {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    &__price {
      font-weight: bold;
      font-size: 18px;
      line-height: 120%;
      color: #340f06;
      align-self: center;
    }
  }
}
</style>
