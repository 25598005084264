<template>
  <div id="app">
    <div v-if="loaded">
      <router-view />
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Loader from "./components/Loader";

/**
 * Точка входа в приложение
 */
export default {
  name: "App",

  components: { Loader },

  computed: {
    ...mapGetters("Products", ["items", "loaded", "cities"]),
  },

  methods: {
    ...mapActions("Products", ["load"]),
    ...mapMutations("City", ["SET_CITY"]),
  },

  async created() {
    // Установка токена если он пришел в приложение
    const token = this.$route.query.access_token;
    if (token !== undefined) {
      sessionStorage.setItem("token", token);
    }

    const whitelabel = this.$route.query.whitelabel;
    if (whitelabel !== undefined) {
      sessionStorage.setItem("hideLogo", "1");
    }

    await this.load();

    const selectedCity = sessionStorage.getItem("city");
    this.SET_CITY(selectedCity ?? this.cities[0]?.id);
  },
};
</script>
<style lang="scss">
@import "assets/fonts/GothamPro/stylesheet.css";

* {
  outline: none;
  vertical-align: baseline;
  box-sizing: border-box !important;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  font-family: "Gotham Pro", sans-serif;
}

#app {
  width: 100%;
  min-height: 100vh;
  margin: auto;
}

html {
  &.page-modal {
    overflow: hidden !important;

    body {
      overflow-y: scroll !important;
    }
  }
}
</style>
