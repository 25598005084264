<template>
  <button
    class="button"
    :type="submit ? 'submit' : 'button'"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
/**
 * Компонент обертка для кнопки
 */
export default {
  name: "FormButton",

  props: {
    /**
     * этот проп указывает или кнопка тип submit
     * @values true | false
     */
    submit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.button {
  background: #c8ad7d;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  height: 44px;
  width: 100%;
  padding: 0 15px;

  &--invert {
    background: none;
    color: #c7bca8;
  }

  &--between {
    justify-content: space-between;
  }
}
</style>
